
$(document).ready(function() {
  var array_ids = [
    document.getElementById("wine_shop_nav"),
    document.getElementById("tickets_nav"),
    document.getElementById("fairs_nav"),
    document.getElementById("people_places_nav"),
    document.getElementById("all_drinks_nav"),
    document.getElementById("blog_nav")
  ]

  let url = document.URL;
  for (let i = 0; i < array_ids.length; i++) {  
    if (url.includes("/wine") && i == 4) { 
      var btn_filter = document.getElementById("show_filter_btn");
      validateID(array_ids[i], true); 
      validateSpecificIDContent(btn_filter);
    }
    else if (url.includes("/shop") && i == 0) { validateID(array_ids[i], true); }
    else if (url.includes("/buy-tickets") && i == 1) {
       validateID(array_ids[i], true); 
       break;
      }
    else if (url.includes("/fairs") && i == 2) { validateID(array_ids[i], true); }
    else if (url.includes("/profile") && i == 3) { validateID(array_ids[i], true); }
    else if (url.includes("/learn/") && i == 5) { validateID(array_ids[i], true); }
    else{ validateSpecificIDContent(array_ids[i], false); }
  }

  // ---| FUNCTIONS

  function validateID(value_id, to_add){
    if(value_id){
      if(to_add){ value_id.classList.add("active"); }
      else{ value_id.classList.remove("active"); }
    }
  }

  function validateSpecificIDContent(value_id){
    if(value_id){
      value_id.classList.remove("d-none");
      value_id.classList.remove("active");
     }
  }
});
